import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { Profile } from 'src/app/auth/models';
import { SignOut } from 'src/app/auth/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];

  @Input() isSignedIn?: boolean;
  @Input() profile?: Profile | null;

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();
  @Output() signOut = new EventEmitter<void>();

  fixed = false;
  showActionsSubnav = false;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private router: Router
  ) {
    router.events.subscribe(s => {
      if (s instanceof NavigationStart && this.menuActive) {
        this.closeOverlay.emit();
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(20))
        .subscribe(a => {
          // show scrollbar after windowinnerheight (window.innerHeight)
          if (window.scrollY > 96) {
            this.fixed = true;
          } else {
            this.fixed = false;
          }
          this.cd.detectChanges();
        });
    }
  }

  isHomeUrl(url: string | undefined) {
    const homeMatches = ['#', '/', ''];
    return homeMatches.includes(url || '');
  }
}
