import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() status: 'danger' | 'success' | 'warning' | 'info' = 'danger';
  @Input() title?: string = '';
  @Input() message?: string;
}
