import * as i0 from '@angular/core';
import { EventEmitter, Directive, Output, Input, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
class InViewportDirective {
  constructor(element) {
    this.element = element;
    this.fstrInViewport = new EventEmitter();
    this.delay = 500;
    this.once = true;
    this.threshold = 0;
    this._isIntersecting = false;
  }
  ngAfterViewInit() {
    if (typeof window !== 'undefined' && window['IntersectionObserver'] !== undefined) {
      setTimeout(() => {
        const callback = e => {
          if (e[0].isIntersecting) {
            this._isIntersecting = true;
            this.fstrInViewport.emit(this._isIntersecting);
            if (this.once) {
              observer.disconnect();
            }
          } else if (this._isIntersecting && !e[0].isIntersecting) {
            this._isIntersecting = false;
            this.fstrInViewport.emit(this._isIntersecting);
          }
        };
        const observer = new IntersectionObserver(callback, {
          threshold: [this.threshold]
        });
        observer.observe(this.element.nativeElement);
      }, this.delay);
    }
  }
  static {
    this.ɵfac = function InViewportDirective_Factory(t) {
      return new (t || InViewportDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: InViewportDirective,
      selectors: [["", "fstrInViewport", ""]],
      inputs: {
        delay: "delay",
        once: "once",
        threshold: "threshold"
      },
      outputs: {
        fstrInViewport: "fstrInViewport"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InViewportDirective, [{
    type: Directive,
    args: [{
      selector: '[fstrInViewport]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    fstrInViewport: [{
      type: Output
    }],
    delay: [{
      type: Input
    }],
    once: [{
      type: Input
    }],
    threshold: [{
      type: Input
    }]
  });
})();
class InViewModule {
  static {
    this.ɵfac = function InViewModule_Factory(t) {
      return new (t || InViewModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: InViewModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InViewModule, [{
    type: NgModule,
    args: [{
      declarations: [InViewportDirective],
      exports: [InViewportDirective],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InViewModule, InViewportDirective };
