<div class="topbar" [class.set--fixed]="fixed">
  <div class="container container-size-xl topbar__container">
    <h1 class="topbar__logo mb-0">
      <a [routerLink]="'/vragenlijsten'">
        <img class="topbar__logo" src="/assets/gfx/integrad-logo.svg" />
      </a>
    </h1>

    <nav class="topbar__nav">
      <ul class="topbar__nav__list d-none d-md-flex">
        <li class="topbar__nav__list__item">
          <a
            [routerLink]="['/']"
            class="button button-link button--primary button--sm"
            [routerLinkActiveOptions]="{ exact: isHomeUrl('/') }"
            [routerLinkActive]="'is--current'"
          >
            <span>Home</span>
          </a>
        </li>
        <li class="topbar__nav__list__item" *ngFor="let page of menu">
          <a
            [routerLink]="page.url"
            class="button button-link button--primary button--sm"
            [routerLinkActiveOptions]="{ exact: isHomeUrl(page.url) }"
            [tabIndex]="menuActive ? -1 : 0"
            [routerLinkActive]="'is--current'"
          >
            <span>{{ page.title }}</span>
          </a>
        </li>
      </ul>

      <a [routerLink]="['/account/inloggen']" class="button button-link button--primary button--sm" *ngIf="!isSignedIn">
        <fstr-icon class="icon-md" anchor="icon-sign-in"></fstr-icon>
        <span class="d-none d-md-inline-block">Inloggen</span>
      </a>

      <!-- Toevoegen van UITLOGGEN Button -->

      <!-- UITLOGGEN Button  -->
      <li *ngIf="isSignedIn" class="action-bar__item">
        <button class="button button-link button--primary button--sm" (click)="showActionsSubnav = !showActionsSubnav">
          <ng-container *ngIf="!showActionsSubnav">
            <span>{{ profile?.fullName }}</span>
            <fstr-icon anchor="icon-chevron-down"></fstr-icon>
          </ng-container>
          <a class="button button-link button--primary button--sm" *ngIf="showActionsSubnav">
            <span>Sluiten</span>
            <fstr-icon anchor="icon-chevron-up"></fstr-icon>
          </a>
        </button>
        <ul class="action-bar__subnav" [class.set--active]="showActionsSubnav">
          <li class="action-bar__subnav__item mb-3">
            <a class="button button-link button--primary button--sm" [routerLink]="'/profiel'" (click)="showActionsSubnav = false">
              <fstr-icon anchor="icon-user"></fstr-icon>
              <span>Mijn profiel</span>
            </a>
          </li>
          <li class="action-bar__subnav__item">
            <button class="button button-link button--primary button--sm" (click)="signOut.emit(); showActionsSubnav = false">
              <fstr-icon anchor="icon-sign-out"></fstr-icon>
              <span>Uitloggen</span>
            </button>
          </li>
        </ul>
      </li>

      <button
        class="hamburger text-primary d-block d-md-none"
        [class.is--active]="menuActive"
        (click)="toggleOverlay.emit()"
        [title]="menuActive ? 'Sluit menu' : 'Open menu'"
      >
        <span class="hamburger__lines"></span>
      </button>
    </nav>
  </div>
</div>

<div class="strokes">
  <div class="stroke" *ngFor="let item of [0, 1, 2, 3, 4, 5, 6]"></div>
</div>

<div class="topbar__overlay section-inset-y d-md-none" *ngIf="menuActive">
  <nav class="topbar__overlay__nav text-center">
    <li class="">
      <a
        [routerLink]="['/']"
        class="button button-link button--light button--lg"
        [routerLinkActiveOptions]="{ exact: isHomeUrl('/') }"
        [routerLinkActive]="'is--current'"
        (click)="closeOverlay.emit()"
      >
        <span>Home</span>
      </a>
    </li>
    <li class="" *ngFor="let page of menu">
      <a
        [routerLink]="page.url"
        class="button button-link button--light button--lg"
        (click)="closeOverlay.emit()"
        [routerLinkActive]="'is--current'"
        [routerLinkActiveOptions]="{ exact: isHomeUrl(page.url) }"
        [tabIndex]="menuActive ? -1 : 0"
      >
        <span>{{ page.title }}</span>
      </a>
    </li>
  </nav>
</div>
