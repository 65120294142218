import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ShowCookieSettings } from '@teamfoster/sdk/cookie-ngrx';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { MenuItem } from '@teamfoster/sdk/menu-ngrx';
import { ContentPage } from 'src/app/content/models';

@Component({
  selector: 'app-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() schema: any;
  @Input() menu: fromMenu.MenuItem[] = [];
  @Input() socials: [] = [];
  @Input() domain: string = '';
  year = new Date().getFullYear();

  private dict = new FromDictionaryPipe(this.lang);

  constructor(
    private store: Store,
    private lang: LanguageService
  ) {}

  ngOnInit(): void {
    this.schema = {
      '@context': 'http://schema.org',
      '@type': this.dict.transform('json-ld-meta-type'),
      'name': this.dict.transform('meta-titel'),
      'url': this.domain,
      'logo': `${this.domain}/assets/gfx/logo-spoorzone.svg`,
      'contactPoint': {
        '@type': 'ContactPoint',
        'email': this.dict.transform('contact-email'),
        'contactType': 'customer service',
      },
      'sameAs': [],
    };

    this.socials.forEach(s => {
      const socialLink = s; // to do config with social buttons
      this.schema.sameAs.push(socialLink);
    });
  }

  openCookie() {
    this.store.dispatch(ShowCookieSettings());
  }
}
