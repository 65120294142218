<footer class="footer py-5 triangle-clip-inverted">
  <div class="triangle-cut">
    <div class="triangle-cut__inner"></div>
  </div>
  <h3 class="text-secondary">
    Inspired by
    <strong class="text-light">your</strong>
    ambitions
  </h3>
  <ul class="footer__nav-list pt-3 pb-3">
    <li class="footer__nav-list__item me-2">{{ year }} &copy; {{ 'meta-titel-suffix' | fromDictionary }}</li>
    <li class="footer__nav-list__item">
      <button (click)="openCookie()" class="button-link">
        <span>{{ 'cookie-settings' | fromDictionary }}</span>
      </button>
    </li>

    <li *ngFor="let item of menu">
      <a [routerLink]="item.routerLink" class="text-secondary">{{ item.title }}</a>
    </li>
  </ul>
  <app-json-ld [json]="schema"></app-json-ld>
</footer>
