import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { IconModule } from '@teamfoster/sdk/icon';
import { GridOverlayComponent } from './components/grid-overlay/grid-overlay.component';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ImageModule } from '@teamfoster/sdk/image';
import { SeoModule } from '../seo/seo.module';
import { AlertComponent } from './components/alert/alert.component';

const moduleExports = [LoaderIndicatorComponent, TopbarComponent, FooterComponent, GridOverlayComponent, AlertComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    InViewModule,
    TextUtilityModule,
    Html5VideoPlayerModule,
    IconModule,
    DictionaryNgrxModule,
    SeoModule,
    ImageModule,
  ],
  declarations: [...moduleExports],
  exports: [...moduleExports],
  providers: [],
})
export class CoreModule {}
